exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-best-breweries-js": () => import("./../../../src/pages/best-breweries.js" /* webpackChunkName: "component---src-pages-best-breweries-js" */),
  "component---src-pages-best-breweries-list-australia-js": () => import("./../../../src/pages/best-breweries/list/australia.js" /* webpackChunkName: "component---src-pages-best-breweries-list-australia-js" */),
  "component---src-pages-best-breweries-list-canada-js": () => import("./../../../src/pages/best-breweries/list/canada.js" /* webpackChunkName: "component---src-pages-best-breweries-list-canada-js" */),
  "component---src-pages-best-breweries-list-country-js": () => import("./../../../src/pages/best-breweries/list/country.js" /* webpackChunkName: "component---src-pages-best-breweries-list-country-js" */),
  "component---src-pages-best-breweries-list-international-metros-js": () => import("./../../../src/pages/best-breweries/list/international-metros.js" /* webpackChunkName: "component---src-pages-best-breweries-list-international-metros-js" */),
  "component---src-pages-best-breweries-list-metros-js": () => import("./../../../src/pages/best-breweries/list/metros.js" /* webpackChunkName: "component---src-pages-best-breweries-list-metros-js" */),
  "component---src-pages-best-breweries-list-united-states-js": () => import("./../../../src/pages/best-breweries/list/united-states.js" /* webpackChunkName: "component---src-pages-best-breweries-list-united-states-js" */),
  "component---src-pages-deletion-js": () => import("./../../../src/pages/deletion.js" /* webpackChunkName: "component---src-pages-deletion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-au-js": () => import("./../../../src/templates/au.js" /* webpackChunkName: "component---src-templates-au-js" */),
  "component---src-templates-ca-js": () => import("./../../../src/templates/ca.js" /* webpackChunkName: "component---src-templates-ca-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-international-metros-js": () => import("./../../../src/templates/international-metros.js" /* webpackChunkName: "component---src-templates-international-metros-js" */),
  "component---src-templates-metros-js": () => import("./../../../src/templates/metros.js" /* webpackChunkName: "component---src-templates-metros-js" */),
  "component---src-templates-new-js": () => import("./../../../src/templates/new.js" /* webpackChunkName: "component---src-templates-new-js" */),
  "component---src-templates-st-js": () => import("./../../../src/templates/st.js" /* webpackChunkName: "component---src-templates-st-js" */)
}

